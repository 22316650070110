import React from "react";
import GAEventHelper from "../../utils/GAEventHelper";

export default class extends React.Component {
  onClick = (event) => {
    GAEventHelper.triggerClickEvent({
      category: this.props.category,
      action: this.props.action,
      label: this.props.label,
      options: {
        window,
        version: this.props.version,
      },
    });
  };
  render() {
    return <span onClick={this.onClick}>{this.props.children}</span>;
  }
}

import React, { useContext } from "react";
import { AppContext } from "../context";
import GAEvent from "../components/GA/GAEvent";

export default () => {
  const [state] = useContext(AppContext);
  return (
    <GAEvent
      category={"phone-number"}
      action={"click"}
      label={"phoneNumberInBanner"}
    >
      <a
        className="sm-phone-number"
        href={"tel:" + state.phoneNumber.internationalFormat}
      >
        <img
          src="/img/call-answer_30x.png"
          alt="Plombier à Toulouse"
          width="22px"
        />
        {state.phoneNumber.default}
      </a>
    </GAEvent>
  );
};

import React, { useState, useEffect } from "react";
import "./legal.css";

import showdown from "showdown";

export default ({ text }) => {
  const [html, setHtml] = useState("");
  useEffect(() => {
    const converter = new showdown.Converter();
    const html = converter.makeHtml(text);
    setHtml(html);
  }, [html]);
  return (
    <section>
      <div
        className="container legal-container"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </section>
  );
};

import React from "react";
import Layout from "../layouts/Layout";
import Index from "../components/pages/mentions-legales";
import mentionsLegales from "../assets/md/mentions-legales";

export default () => {
  return (
    <Layout>
      <Index text={mentionsLegales} />
    </Layout>
  );
};

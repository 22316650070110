import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";

import { AppContextProvider } from "../context";
import { useAppInitialization } from "../hooks";

import Footer from "./Footer";
import Header from "./Header";
import PhoneNumberBanner from "./PhoneNumberBanner";

import SEO from "../components/elements/SEO";

import "../styles/global.css";

const App = ({
  reference,
  className = "",
  hidePhoneNumberBanner = false,
  metadata = {
    title: "",
    description: "",
  },
  children,
}) => {
  useAppInitialization();

  const [pathname, setPathname] = useState(null);
  useEffect(() => {
    setPathname(window.location.pathname);
  }, [pathname]);

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery_ {
          site {
            siteMetadata {
              title
              url
              description
            }
          }
        }
      `}
      render={(data) => (
        <div>
          <SEO
            siteMetadata={data.site.siteMetadata}
            title={metadata.title}
            description={metadata.description}
            pathname={pathname}
            {...metadata}
          />
          <div className="App nick-App">
            <div className={className}>
              <Header reference={reference} />

              {children}

              {!hidePhoneNumberBanner && <PhoneNumberBanner />}
            </div>
            <Footer hasSMBanner={!hidePhoneNumberBanner} />
          </div>
        </div>
      )}
    />
  );
};

export default ({ children, ...props }) => {
  return (
    <AppContextProvider>
      <App {...props}>{children}</App>
    </AppContextProvider>
  );
};
